<template>
  <card-loader :title="widget.display_type === 'list' ? 'Events' : undefined" :loading="isLoading">
    <template v-if="events && events.length > 0">
      <events-list-card :events v-if="widget.display_type === 'list'" />
      <events-carousel-card :events v-if="widget.display_type === 'carousel'" />
      <router-link
        class="btn btn-outline w-full"
        v-if="events && events.length > 0"
        :to="{ name: 'community_events.index' }">
        View All Events
      </router-link>
    </template>
    <empty-view title="No events found" subtitle="Check back later for updates." v-else>
      <events-empty-icon class="h-40 w-40" />
    </empty-view>
    <error-message :error="error" v-if="error" />
  </card-loader>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query"
import EventsCarouselCard from "/js/components/BlockBuilder/Cards/EventsCarouselCard.vue"
import EventsListCard from "/js/components/BlockBuilder/Cards/EventsListCard.vue"
import CardLoader from "/js/components/BlockBuilder/Cards/CardLoader.vue"
import type { EventWidget } from "/js/components/BlockBuilder/Widget"
import { computed } from "vue"
import { WidgetsApi } from "/js/services/WidgetsApi"
import EventsEmptyIcon from "/js/components/icons/EventsEmptyIcon.vue"
import EmptyView from "/js/components/utilities/UI/EmptyView.vue"
import ErrorMessage from "/js/components/ErrorMessage.vue"

const props = defineProps<{
  widgetId: string
  widget: EventWidget
}>()

const {
  data: events,
  isLoading,
  error,
} = useQuery({
  queryKey: computed(() => ["widget_event_list", props.widgetId]),
  queryFn: async () => await WidgetsApi.getEvents(props.widgetId),
})
</script>

<script lang="ts">
export default {
  name: "CommunityEventsCardLoader",
}
</script>
