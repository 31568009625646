<template>
  <block-card
    v-if="element.type === 'block'"
    :title="element.data.title"
    :description="element.data.description"
    :bg="element.data.bg"
    :block-type="element.data.blockType" />
  <template v-else-if="element.type === 'widget'">
    <div class="relative w-full">
      <block-widget-card-element
        :widget="element.data"
        :children="element.children"
        :edit
        @deleteElement="emit('deleteElement', $event)"
        @editElement="emit('editElement', $event)"
        @draggableChanged="emit('draggableChanged', $event)" />

      <div class="absolute right-2 top-2" v-if="edit">
        <dropdown-menu :portal="false" menuItemsWrapperClass="z-10">
          <div class="px-4 py-1 text-xs text-secondary text-center">
            {{ getWidgetTypeTitle(element) }} Widget
          </div>
          <template #button>
            <icon-button>
              <ellipsis-icon class="h-4 w-4 text-gray-600 hover:text-gray-500" aria-hidden="true" />
            </icon-button>
          </template>
          <dropdown-menu-item @click="emit('editElement', element)">Edit</dropdown-menu-item>

          <dropdown-menu-item variant="danger" @click="deleteElement = element">
            Delete
          </dropdown-menu-item>
        </dropdown-menu>
      </div>
    </div>

    <confirmation-item-modal
      title="Delete this widget?"
      v-model="deleteElement"
      @confirm="handleDelete"
      #default="{ data }">
      Delete this {{ getWidgetTypeTitle(data) }} widget?
    </confirmation-item-modal>
  </template>
</template>

<script setup lang="ts">
import type { BlockWidget, BlockWidgetCardEvents } from "/js/components/BlockBuilder/Widget"
import BlockCard from "/js/components/BlockBuilder/Cards/BlockCard.vue"
import DropdownMenu from "/js/components/DropdownMenu.vue"
import DropdownMenuItem from "/js/components/DropdownMenuItem.vue"
import ConfirmationItemModal from "/js/components/Modals/ConfirmationItemModal.vue"
import { ref } from "vue"
import { getWidgetTypeTitle } from "/js/components/BlockBuilder/Widget"
import BlockWidgetCardElement from "/js/components/BlockBuilder/Cards/BlockWidgetCardElement.vue"
import IconButton from "/js/components/IconButton.vue"
import EllipsisIcon from "/js/components/icons/EllipsisIcon.vue"

const { edit = false, element } = defineProps<{
  element: BlockWidget
  edit?: boolean
}>()

const emit = defineEmits<BlockWidgetCardEvents>()

const deleteElement = ref<BlockWidget | null>(null)

const handleDelete = () => {
  if (!deleteElement.value) {
    return
  }
  emit("deleteElement", element)
  deleteElement.value = null
}
</script>

<script lang="ts">
export default {
  name: "BlockWidgetCard",
}
</script>
