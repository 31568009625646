<template>
  <card-loader :title="widget.title">
    <div class="editor-minimal readonly my-4" v-if="widget.body">
      <editor-wrapper :initial-data="widget.body" readonly v-if="widget.body" />
    </div>

    <div class="flex justify-start" v-if="widget.show_cta">

      <a :href="externalCtaUrl" target="_blank" class="btn btn-primary" v-if="externalCtaUrl">
        {{ widget.cta_label }}
      </a>

      <router-link :to="internalCtaRoute" target="_blank" class="btn btn-primary" v-if="internalCtaRoute">
        {{ widget.cta_label }}
      </router-link>

    </div>
  </card-loader>
</template>

<script setup lang="ts">
import EditorWrapper from "/js/components/utilities/Editor/EditorWrapper.vue"
import type { RichTextWidget } from "/js/components/BlockBuilder/Widget"
import CardLoader from "/js/components/BlockBuilder/Cards/CardLoader.vue"
import { computed } from "vue"
import type { RouteLocationNamedRaw } from "vue-router"
import { navigationResourceRoute } from "/js/components/NavigationResources/NavigationResourcesTypes"

const props = defineProps<{
  widget: RichTextWidget
}>()

const externalCtaUrl = computed(() => {
  if (props.widget.cta_url_type === "external" || !props.widget.cta_url_type) {
    return props.widget.cta_url
  } else if (props.widget.cta_url_type === "internal" && props.widget.cta_url_object) {
  }
  return undefined
})

const internalCtaRoute = computed((): RouteLocationNamedRaw | undefined => {
  if (props.widget.cta_url_type === "internal" && props.widget.cta_url_object) {
    return navigationResourceRoute(props.widget.cta_url_object)
  }
  return undefined
})
</script>

<script lang="ts">
export default {
  name: "RichTextCard",
}
</script>
