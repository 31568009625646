<template>
  <card-loader :loading="isLoading">
    <template v-if="widget.display_type === 'carousel'">
      <feed-carousel-card
        :posts
        :title="widget.title"
        @follow="toggleFollowPost"
        v-if="posts" />
    </template>
    <template v-else-if="widget.display_type === 'list'">
      <feed-list-card
        :posts
        @follow="toggleFollowPost"
        @delete="invalidateQueries"
        @update="invalidateQueries"
        v-if="posts" />
    </template>
    <error-message :error v-if="error" />
  </card-loader>
</template>

<script setup lang="ts">
import ErrorMessage from "/js/components/ErrorMessage.vue"
import CardLoader from "/js/components/BlockBuilder/Cards/CardLoader.vue"
import { useQuery, useQueryClient } from "@tanstack/vue-query"
import { computed } from "vue"
import { WidgetsApi } from "/js/services/WidgetsApi"
import type { FeedWidget } from "/js/components/BlockBuilder/Widget"
import FeedCarouselCard from "/js/components/BlockBuilder/Cards/FeedCarouselCard.vue"
import type { Post } from "/js/models/Post"
import { usePostService, widgetFeedPostsQueryKey } from "/js/services/usePostService"
import { toastError } from "/js/composables/toast"
import FeedListCard from "/js/components/BlockBuilder/Cards/FeedListCard.vue"
import { useRouter } from "vue-router"

const props = defineProps<{
  widgetId: string
  widget: FeedWidget
}>()

const queryClient = useQueryClient()

const { followPostObject } = usePostService(undefined, [
  {
    key: widgetFeedPostsQueryKey(props.widgetId),
    type: "list",
  },
])

const {
  data: posts,
  isLoading,
  error,
} = useQuery({
  queryKey: computed(() => widgetFeedPostsQueryKey(props.widgetId)),
  queryFn: async () => await WidgetsApi.getPosts(props.widgetId),
})

const toggleFollowPost = async (post: Post) => {
  try {
    await followPostObject(post)
    // syncItem([{ key: widgetFeedPostsQueryKey(props.widgetId), type: "list" }], post)
  } catch (e) {
    toastError("Something went wrong")
  }
}

const router = useRouter()

const invalidateQueries = async (post: Post) => {
  await queryClient.invalidateQueries({ queryKey: widgetFeedPostsQueryKey(props.widgetId) })
}
</script>

<script lang="ts">
export default {
  name: "CommunityFeedCardLoader",
}
</script>
