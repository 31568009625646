<template>
  <div class="card relative overflow-auto">
    <div v-if="!elements.length" class="drop-zone-placeholder pointer-events-none absolute inset-4">
      Drop a new widget in here!
      {{ draggingElement }}
    </div>
    <draggable
      class="grid min-h-40 gap-4"
      :class="[columnsGridClass, edit ? 'p-4' : '', draggableDisabled ? 'cursor-not-allowed opacity-50' : '']"
      :list="elements"
      :animation="200"
      item-key="id"
      group="items"
      @start="startDrag($event)"
      @end="endDrag"
      filter=".btn"
      :disabled="draggableDisabled"
      @change="emit('draggableChanged', { event: $event, parentId: widgetId })"
      ghost-class="sidebar-ghost">
      <template #item="{ element }">
        <div :class="{ 'drop-zone-placeholder': edit }">
          <block-widget-card
            @editElement="emit('editElement', $event)"
            @deleteElement="emit('deleteElement', $event)"
            :element
            :edit />
        </div>
      </template>
    </draggable>
  </div>
</template>

<script setup lang="ts">
import draggable from "vuedraggable"
import {
  type BlockWidget,
  type BlockWidgetCardEvents,
  type ColumnsWidget, getWidgetType,
} from "/js/components/BlockBuilder/Widget"
import BlockWidgetCard from "/js/components/BlockBuilder/Cards/BlockWidgetCard.vue"
import { computed, ref, watch, toRefs } from "vue"
import { useDraggingElement } from "/js/components/BlockBuilder/useDraggingElement"

const { draggingElement, endDrag, startDrag } = useDraggingElement()

const {
  edit = false,
  children,
  widget,
} = defineProps<{
  widgetId: string
  widget: ColumnsWidget
  children?: BlockWidget[]
  edit?: boolean
}>()

const elements = ref<BlockWidget[]>([])

const draggableDisabled = computed(() => {
  if (!edit) return true
  if (!draggingElement.value) return false
  return getWidgetType(draggingElement.value) === "ColumnsWidget";
})

watch(
  () => children,
  (newChildren) => {
    elements.value = newChildren || []
  },
  { immediate: true }
)

const emit = defineEmits<BlockWidgetCardEvents>()

const columnsCount = computed(() => widget.columns)

const columnsGridClass = computed(() => {
  switch (columnsCount.value) {
    case 2:
      return "lg:grid-cols-2"
    case 3:
      return "lg:grid-cols-3"
    case 4:
      return "lg:grid-cols-4"
    default:
      return "lg:grid-cols-2"
  }
})
</script>

<script lang="ts">
export default {
  name: "ColumnsCard",
}
</script>
