<template>
  <div class="card">
    <gradient-image
      class="aspect-[21/9] w-full rounded-t-lg"
      :id="widgetId"
      :url="widget.cover_url" />

    <div class="flex flex-col gap-4 p-8">
      <div class="text-primary font-medium">{{ widget.subtitle }}</div>
      <h3 class="text-primary font-medium">{{ widget.title }}</h3>
      <div class="text-secondary whitespace-pre-line">{{ widget.body }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import GradientImage from "/js/components/GradientImage.vue"
import type { HeaderWidget } from "/js/components/BlockBuilder/Widget"

defineProps<{
  widgetId: string
  widget: HeaderWidget
}>()

</script>

<script lang="ts">
export default {
  name: "HeaderCard",
}
</script>
