<template>
  <div
    class="flex flex-1 flex-col items-center justify-between overflow-y-auto scrollbar:h-0 scrollbar:w-0">
    <div class="flex h-full flex-col items-center gap-4">
      <schools-nav class="h-11 w-11" />

      <router-link :to="{ name: 'learner_dashboard' }" #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <DashboardFillIcon class="h-6 w-6" v-if="isActive" />
            <DashboardIcon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Dashboard</span>
        </span>
      </router-link>

      <router-link
        :to="{ name: communityMembersRouteNames.members }"
        #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <Users2FillIcon class="h-6 w-6" v-if="isActive" />
            <Users2Icon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Members</span>
        </span>
      </router-link>

      <router-link
        :to="{ name: communityContentRouteNames.community_content_index }"
        #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <FolderFillIcon class="h-6 w-6" v-if="isActive" />
            <FolderIcon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Library</span>
        </span>
      </router-link>

      <router-link :to="{ name: 'learning_hubs.index' }" #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <BookFillIcon class="h-6 w-6" v-if="isActive" />
            <BookIcon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Learning</span>
        </span>
      </router-link>

      <router-link
        :to="{ name: careerRouteNames.careerIndex }"
        #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <span class="relative">
              <BriefcaseFillIcon class="h-6 w-6" v-if="isActive" />
              <BriefcaseIcon class="h-6 w-6" v-else />
              <unread-dot class="absolute right-0 top-0 -mr-1 -mt-1" v-if="hasBadge" />
            </span>
          </span>
          <span class="text-xxs">Career</span>
        </span>
      </router-link>

      <router-link :to="{ name: 'community_events.index' }" #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <CalendarFillIcon class="h-6 w-6" v-if="isActive" />
            <CalendarIcon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Events</span>
        </span>
      </router-link>

      <router-link :to="{ name: 'chat' }" #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <ChatBubbleMultiFill class="h-6 w-6" v-if="isActive" />
            <ChatBubbleMulti class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Chat</span>
        </span>
      </router-link>

      <router-link
        v-if="hasCommunityAdminAccess"
        to="/admin"
        #default="{ href, navigate, isActive }">
        <span class="flex flex-col items-center">
          <span class="rounded-lg p-2" :class="{ 'bg-gray-300': isActive }">
            <CogIconFill class="h-6 w-6" v-if="isActive" />
            <CogIcon class="h-6 w-6" v-else />
          </span>
          <span class="text-xxs">Admin</span>
        </span>
      </router-link>

      <div class="flex-1"></div>

      <div class="flex flex-col items-center gap-4 lg:hidden">
        <ProfileSidebarItems />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SchoolsNav from "/js/components/Layout/SchoolsNav.vue"
import CalendarFillIcon from "/js/components/icons/CalendarFillIcon.vue"
import CalendarIcon from "/js/components/icons/CalendarIcon.vue"
import BookFillIcon from "/js/components/icons/BookFillIcon.vue"
import BookIcon from "/js/components/icons/BookIcon.vue"
import ChatBubbleMultiFill from "/js/components/icons/ChatBubbleMultiFill.vue"
import ChatBubbleMulti from "/js/components/icons/ChatBubbleMulti.vue"
import CogIconFill from "/js/components/icons/CogIconFill.vue"
import CogIcon from "/js/components/icons/CogIcon.vue"
import { useGate } from "/js/composables/useGate"
import { computed } from "vue"
import DashboardIcon from "/js/components/icons/DashboardIcon.vue"
import DashboardFillIcon from "/js/components/icons/DashboardFillIcon.vue"
import { BriefcaseIcon, FolderIcon } from "@heroicons/vue/24/outline"
import {
  BriefcaseIcon as BriefcaseFillIcon,
  FolderIcon as FolderFillIcon,
} from "@heroicons/vue/24/solid"
import { careerRouteNames } from "/js/router/CareerRoutes"
import { useQuery } from "@tanstack/vue-query"
import { JobSearchApi } from "/js/services/JobSearchApi"
import UnreadDot from "/js/components/icons/UnreadDot.vue"
import { communityMembersRouteNames } from "/js/router/MembersRoutes"
import Users2Icon from "/js/components/icons/Users2Icon.vue"
import Users2FillIcon from "/js/components/icons/Users2FillIcon.vue"
import { communityContentRouteNames } from "/js/router/CommunityContentRoutes"
import ProfileSidebarItems from "/js/components/Layout/Sidebar/ProfileSidebarItems.vue"

const { hasCommunityAdminAccess } = useGate()

const { data: jobMonitorBadge } = useQuery({
  queryKey: ["job_monitor_badge"],
  queryFn: async () => await JobSearchApi.getJobMonitorBadge(),
})

const hasBadge = computed(() => !!jobMonitorBadge.value?.monitor_badge)
</script>

<script lang="ts">
export default {
  name: "SmallSidebarV2",
}
</script>
