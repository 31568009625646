import { z } from "zod"
import { BlockCards, homeBlockCards } from "/js/components/BlockBuilder/BlockCardTypes"
import { widgetFormData } from "/js/components/BlockBuilder/WidgetFormData"
import type { BlockBuilderConfig } from "/js/components/BlockBuilder/BlockBuilderConfig"
import { ZNavigationResource } from "/js/components/NavigationResources/NavigationResourcesTypes"

export const WidgetPlacements = ["main", "side"] as const
export const MembersWidgetMemberTypes = ["admin_member", "user_member", "all_member"] as const
export const ContentWidgetDisplayTypes = ["preview", "full"] as const
export const CarouselDisplayTypes = ["carousel", "list"] as const
export const ProductWidgetScopeTypes = ["product_overview", "product_faq"] as const
export const CommunityWidgetScopeTypes = ["community_home", "community_registration"] as const
export const WidgetScopeTypes = [...ProductWidgetScopeTypes, ...CommunityWidgetScopeTypes] as const
export const WidgetPageTypes = ["one_column", "two_column"] as const
export const RichTextWidgetUrlTypes = ["internal", "external"] as const

export type RichTextWidgetUrlType = (typeof RichTextWidgetUrlTypes)[number]

export type WidgetPageCategory =
  | {
      type: "community"
    }
  | {
      type: "product"
      product_id: string
    }

export type WidgetPlacement = (typeof WidgetPlacements)[number]
export type MembersWidgetMemberType = (typeof MembersWidgetMemberTypes)[number]
export type CarouselDisplayType = (typeof CarouselDisplayTypes)[number]
export type ContentWidgetDisplayType = (typeof ContentWidgetDisplayTypes)[number]
export type ProductWidgetScopeType = (typeof ProductWidgetScopeTypes)[number]
export type CommunityWidgetScopeType = (typeof CommunityWidgetScopeTypes)[number]
export type WidgetScopeType = (typeof WidgetScopeTypes)[number]
export type WidgetPageType = (typeof WidgetPageTypes)[number] // rename this

export const ZHeaderWidget = z.object({
  title: z.string(),
  subtitle: z.string(),
  body: z.string(),
  cover_url: z.string().url().nullable().optional(),
})

export const ZMembersWidget = z.object({
  max_members: z.number().max(5).min(1),
  member_type: z.enum(MembersWidgetMemberTypes),
})

export const ZInstructorsWidget = z.object({
  product_id: z.string(),
})

export const ZFeaturedProductsWidget = z.object({
  product_ids: z.array(z.string()),
})

export const ZChatRoomWidget = z.object({
  chat_room_ids: z.array(z.string()),
  product_id: z.string().nullable(),
})

export const ZCurriculumWidget = z.object({
  product_id: z.string(),
})

export const ZColumnsWidget = z.object({
  title: z.string().nullable(),
  columns: z.number()
})

export const ZContentWidget = z.object({
  display_type: z.enum(ContentWidgetDisplayTypes),
  editor_content_ids: z.string().array().optional().nullable(),
})

export const ZAccordionWidgetItem = z.object({
  id: z.string(),
  title: z.string(),
  content: z.string(),
})

export const ZAccordionWidget = z.object({
  items: ZAccordionWidgetItem.array(),
  title: z.string(),
})

export const ZEventWidget = z.object({
  max_events: z.number().max(5).min(1),
  display_type: z.enum(CarouselDisplayTypes),
})

export const ZFeedWidget = z.object({
  display_type: z.enum(CarouselDisplayTypes),
  title: z.string(),
})

export const ZRichTextWidget = z.object({
  title: z.string(),
  show_cta: z.boolean(),
  cta_label: z.string().nullable().optional(),
  cta_url: z.string().url().nullable().optional(),
  cta_url_type: z.enum(RichTextWidgetUrlTypes).nullable().optional(),
  cta_url_object: ZNavigationResource.nullable().optional(),
  cta_url_object_id: z.string().nullable().optional(),
  cta_url_object_type: z.string().nullable().optional(),
  body: z.any(),
})

export const ZWidgetBase = z.object({
  id: z.string(),
  placement: z.enum(WidgetPlacements).nullable(),
  position: z.number(),
  updated_at: z.date(),
  parent_id: z.string().nullable()
})

const HeaderWidgetType = z.literal("HeaderWidget")
const MembersWidgetType = z.literal("MembersWidget")
const FeaturedProductsWidgetType = z.literal("FeaturedProductsWidget")
const ContentWidgetType = z.literal("ContentWidget")
const EventWidgetType = z.literal("EventWidget")
const FeedWidgetType = z.literal("FeedWidget")
const RichTextWidgetType = z.literal("RichTextWidget")
const InstructorsWidgetType = z.literal("InstructorsWidget")
const AccordionWidgetType = z.literal("AccordionWidget")
const ChatRoomWidgetType = z.literal("ChatRoomWidget")
const CurriculumWidgetType = z.literal("CurriculumWidget")
const ColumnsWidgetType = z.literal("ColumnsWidget")

export const WidgetTypes = [
  HeaderWidgetType.value,
  MembersWidgetType.value,
  FeaturedProductsWidgetType.value,
  ContentWidgetType.value,
  EventWidgetType.value,
  FeedWidgetType.value,
  RichTextWidgetType.value,
  InstructorsWidgetType.value,
  AccordionWidgetType.value,
  ChatRoomWidgetType.value,
  CurriculumWidgetType.value,
  ColumnsWidgetType.value,
] as const

export const WidgetModels = {
  HeaderWidget: {
    model: ZHeaderWidget,
    params: ZHeaderWidget.omit({ cover_url: true }).merge(
      z.object({ cover: z.string().optional().nullable() })
    ),
  },
  MembersWidget: { model: ZMembersWidget, params: ZMembersWidget },
  FeaturedProductsWidget: {
    model: ZFeaturedProductsWidget,
    params: ZFeaturedProductsWidget.partial(),
  },
  ContentWidget: { model: ZContentWidget, params: ZContentWidget },
  EventWidget: { model: ZEventWidget, params: ZEventWidget },
  FeedWidget: { model: ZFeedWidget, params: ZFeedWidget },
  RichTextWidget: { model: ZRichTextWidget, params: ZRichTextWidget },
  InstructorsWidget: { model: ZInstructorsWidget, params: ZInstructorsWidget.partial() },
  AccordionWidget: { model: ZAccordionWidget, params: ZAccordionWidget },
  ChatRoomWidget: { model: ZChatRoomWidget, params: ZChatRoomWidget.partial() },
  CurriculumWidget: { model: ZCurriculumWidget, params: ZCurriculumWidget.partial() },
  ColumnsWidget: { model: ZColumnsWidget, params: ZColumnsWidget }
} as const

export const ZWidget = z.union([
  z.object({ ...ZWidgetBase.shape, type: HeaderWidgetType, ...ZHeaderWidget.shape }),
  z.object({ ...ZWidgetBase.shape, type: MembersWidgetType, ...ZMembersWidget.shape }),
  z.object({
    ...ZWidgetBase.shape,
    type: FeaturedProductsWidgetType,
    ...ZFeaturedProductsWidget.shape,
  }),
  z.object({ ...ZWidgetBase.shape, type: ContentWidgetType, ...ZContentWidget.shape }),
  z.object({ ...ZWidgetBase.shape, type: EventWidgetType, ...ZEventWidget.shape }),
  z.object({ ...ZWidgetBase.shape, type: FeedWidgetType, ...ZFeedWidget.shape }),
  z.object({ ...ZWidgetBase.shape, type: RichTextWidgetType, ...ZRichTextWidget.shape }),
  z.object({ ...ZWidgetBase.shape, type: InstructorsWidgetType, ...ZInstructorsWidget.shape }),
  z.object({ ...ZWidgetBase.shape, type: AccordionWidgetType, ...ZAccordionWidget.shape }),
  z.object({ ...ZWidgetBase.shape, type: ChatRoomWidgetType, ...ZChatRoomWidget.shape }),
  z.object({ ...ZWidgetBase.shape, type: CurriculumWidgetType, ...ZCurriculumWidget.shape }),
  z.object({ ...ZWidgetBase.shape, type: ColumnsWidgetType, ...ZColumnsWidget.shape }),
])

export const ZWidgetParamsBase = z.object({
  placement: z.enum(WidgetPlacements).nullable(),
  position: z.number(),
  parent_id: z.string().nullable().optional(),
})

export const ZWidgetParams = z.union([
  z.object({
    type: HeaderWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.HeaderWidget.params.shape,
  }),
  z.object({
    type: MembersWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.MembersWidget.params.shape,
  }),
  z.object({
    type: FeaturedProductsWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.FeaturedProductsWidget.params.shape,
  }),
  z.object({
    type: ContentWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.ContentWidget.params.shape,
  }),
  z.object({
    type: EventWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.EventWidget.params.shape,
  }),
  z.object({
    type: FeedWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.FeedWidget.params.shape,
  }),
  z.object({
    type: RichTextWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.RichTextWidget.params.shape,
  }),
  z.object({
    type: InstructorsWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.InstructorsWidget.params.shape,
  }),
  z.object({
    type: AccordionWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.AccordionWidget.params.shape,
  }),
  z.object({
    type: ChatRoomWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.ChatRoomWidget.params.shape,
  }),
  z.object({
    type: CurriculumWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.CurriculumWidget.params.shape,
  }),
  z.object({
    type: ColumnsWidgetType,
    ...ZWidgetParamsBase.shape,
    ...WidgetModels.ColumnsWidget.params.shape,
  }),
])

// Utility type to omit 'type' field from each member of the union
// type OmitType<T> = T extends any ? Omit<T, 'type'> : never;

// Create a new type by applying OmitType to each member of the WidgetParams union
// export type WidgetParamsWithoutType = OmitType<WidgetParams>;

export const ZBlockCardProps = z.object({
  title: z.string(),
  description: z.string(),
  bg: z.string(),
  blockType: z.enum(WidgetTypes),
})

export const ZBlockWidgetWidget = z.object({
  id: z.string(),
  type: z.literal("widget"),
  data: ZWidget,
})

export const ZBlockWidgetBlock = z.object({
  id: z.string(),
  type: z.literal("block"),
  data: ZBlockCardProps,
})

export const ZBlockWidget: z.ZodType = z.lazy(() =>
  z.union([ZBlockWidgetWithChildren, ZBlockWidgetBlock])
);

export const ZBlockWidgetWithChildren = ZBlockWidgetWidget.extend({
  children: z.array(ZBlockWidget),
});

export type BlockWidgetWidget = z.infer<typeof ZBlockWidgetWidget>
export type BlockWidgetWithChildren = z.infer<typeof ZBlockWidgetWithChildren>


export const getWidgetType = (widget: BlockWidget): WidgetType => {
  if (widget.type === "widget") {
    return widget.data.type
  } else {
    return widget.data.blockType
  }
}

export const getWidgetTypeTitle = (widget: BlockWidget) => {
  return Object.values(BlockCards).find((c) => c.blockType === getWidgetType(widget))?.title
}

export const getWidget = (widget: BlockWidget): Widget | undefined => {
  if (widget.type === "widget") {
    return widget.data
  }

  return undefined
}

type ModelType<T extends WidgetType> = z.infer<(typeof WidgetModels)[T]["model"]>

export const getWidgetData = <T extends WidgetType>(
  widget: Widget | null | undefined,
  type: T
): ModelType<T> | null => {
  if (widget && widget.type === type) {
    return widget as ModelType<T>
  }

  return null
}

export type WidgetParamsBase = z.infer<typeof ZWidgetParamsBase>
export type BlockWidget = z.infer<typeof ZBlockWidget>
export type BlockCardProps = z.infer<typeof ZBlockCardProps>
export type WidgetParams = z.infer<typeof ZWidgetParams>
export type Widget = z.infer<typeof ZWidget>
export type WidgetType = keyof typeof WidgetModels

export type HeaderWidget = z.infer<typeof ZHeaderWidget>
export type MembersWidget = z.infer<typeof ZMembersWidget>
export type FeaturedProductsWidget = z.infer<typeof ZFeaturedProductsWidget>
export type ContentWidget = z.infer<typeof ZContentWidget>
export type EventWidget = z.infer<typeof ZEventWidget>
export type FeedWidget = z.infer<typeof ZFeedWidget>
export type RichTextWidget = z.infer<typeof ZRichTextWidget>
export type InstructorsWidget = z.infer<typeof ZInstructorsWidget>
export type AccordionWidget = z.infer<typeof ZAccordionWidget>
export type AccordionWidgetItem = z.infer<typeof ZAccordionWidgetItem>
export type ChatRoomWidget = z.infer<typeof ZChatRoomWidget>
export type CurriculumWidget = z.infer<typeof ZCurriculumWidget>
export type ColumnsWidget = z.infer<typeof ZColumnsWidget>

export const makeBlockWidgets = (widgets: Widget[], placement: WidgetPlacement): BlockWidget[] => {
  return widgets
    .filter((w) => w.placement === placement && !w.parent_id)
    .map((w): BlockWidgetWithChildren => {
      return {
        id: `${w.id}-${w.updated_at}`,
        type: "widget",
        data: w,
        children: widgetChildren(widgets, w)
      }
    })
    .sort((a, b) => a.data.position - b.data.position) as BlockWidget[]
}

const widgetChildren = (widgets: Widget[], parent: Widget): BlockWidget[] => {
  return widgets
    .filter((w) => w.parent_id === parent.id)
    .map((w): BlockWidget => {
      return {
        id: `${w.id}-${w.updated_at}`,
        type: "widget",
        data: w,
        children: []
      }
    })
    .sort((a, b) => a.data.position - b.data.position)
}

export type BlockWidgetCardEvents = {
  deleteElement: [value: BlockWidget]
  editElement: [value: BlockWidget]
  draggableChanged: [
    data: {
      event: any
      parentId: string
    },
  ]
}